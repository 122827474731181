import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import { Color, Version } from "../config"

import Background from "../components/shared/Background"
import { useColorsContext, useColorsDataLoading } from "../data/colorsContext"
import { Row, Col } from "antd"

const AboutUsPage = () => {
  const { ctxSettings } = useColorsContext()
  useColorsDataLoading()

  const year = new Date().getFullYear()

  return (
    <Layout
      title={"About Us"}
      description={"About Us"}
      backgroundColor={Color.BLACK}
    >
      <StyledHeading>About Us</StyledHeading>
      <Background path={ctxSettings.aboutUsBackgroundPath} />
      <StyledVersion>v{Version.VERSION}</StyledVersion>
      <Container>
        <StyledRow>
          <Col xs={6} md={4}>
            <StyledTextTitle>About us</StyledTextTitle>
          </Col>
          <Col xs={18} md={14}>
            <StyledText>
              COLORS STUDIO™ IS A MULTIDISCIPLINARY DESIGN STUDIO PROPOSING FULL
              CREATION SERVICE EXCELLING IN ART DIRECTION, AND PRODUCT DESIGN.
              COLORS STUDIO ™ IS MUCH MORE THAN AN INTERJECTION OR EXPRESSION IT
              IS A BRAND AND SYMBOL FOR THE NEW GENERATION OF THE CONTEMPORARY
              ERA.
            </StyledText>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col xs={6} md={4}>
            <StyledTextTitle>Contact</StyledTextTitle>
          </Col>
          <Col xs={18} md={14}>
            <Row>
              <Col md={6} xs={24}>
                <StyledText>GENERAL INQUIRIES</StyledText>
              </Col>
              <Col md={18} xs={24}>
                <StyledTextEmail>INFO@COLORSXSTUDIO.COM</StyledTextEmail>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={24}>
                <StyledText>BUSINESS INQUIRIES</StyledText>
              </Col>
              <Col md={18} xs={24}>
                <StyledTextEmail>MAXIM@COLORSXSTUDIO.COM</StyledTextEmail>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={24}>
                <StyledText>SALES INQUIRIES</StyledText>
              </Col>
              <Col md={18} xs={24}>
                <StyledTextEmail>GATIEN@COLORSXSTUDIO.COM</StyledTextEmail>
              </Col>
            </Row>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col xs={6} md={4}>
            <StyledTextTitle>SOCIAL</StyledTextTitle>
          </Col>
          <Col xs={18} md={14}>
            <StyledText>
              <StyledLink href={ctxSettings.behanceUrl} target="_blank">
                Behance
              </StyledLink>
              <br />
              <StyledLink href={ctxSettings.instagramUrl} target="_blank">
                Instagram
              </StyledLink>
              <br />
              <StyledLink href={ctxSettings.facebookUrl} target="_blank">
                Facebook
              </StyledLink>
            </StyledText>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col xs={6} md={4}>
            <StyledTextTitle>WEBSITE</StyledTextTitle>
          </Col>
          <Col xs={18} md={12}>
            <StyledText>
              DESIGN BY COLORS STUDIO <br />
              DEVELOPMENT BY{" "}
              <a href="https://www.4eyes.be/" target="_blank">
                4 EYES
              </a>
            </StyledText>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col xs={6} md={4}>
            <StyledTextTitle>CREDITS</StyledTextTitle>
          </Col>
          <Col xs={18} md={12}>
            <StyledText>
              ALL IMAGES & VIDEOS <br />
              {year} © ALL RIGHTS RESERVED <br />
              BY COLORS STUDIO
            </StyledText>
          </Col>
        </StyledRow>
      </Container>
    </Layout>
  )
}

export default AboutUsPage

const StyledHeading = styled.h1`
  color: #000;
  z-index: -999999999;
  position: absolute;
`

const Container = styled.div`
  margin-top: 25px;
  color: #fff;
  margin: 50px;
  padding: 5px;
  @media (max-width: 768px) {
    margin: 0;
    padding: 5px;
    padding-top: 115px !important;
    overflow-y: scroll;
    height: 100%;
  }
`

const StyledRow = styled(Row)`
  margin-bottom: 15px;
`

const StyledLink = styled.a`
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`

const StyledVersion = styled.p`
  color: ${() => Color.WHITE};
  position: fixed;
  right: 10px;
  bottom: 10%;
  font-size: 10px;
`

const StyledTextEmail = styled.p`
  @media (max-width: 768px) {
    font-size: 10px;
  }
`

const StyledTextTitle = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
const StyledText = styled.p`
  text-transform: uppercase;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 0px;
  }
`
