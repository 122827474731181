import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from "styled-components"

interface BackgroundProps {
  path?: string
}

const Background = ({ path }: BackgroundProps) => {
  const defaultbgdata = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "default_bg.jpg" }) {
        publicURL
      }
    }
  `)

  return (
    <div>
      <StyledImg src={path ? path : defaultbgdata.image.publicURL} />
    </div>
  )
}

export default Background

const StyledImg = styled.img`
  position: fixed;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  z-index: -99999999;
  /*pointer-events: none;*/
  @media (max-width: 768px) {
    width: auto;
    height: -webkit-fill-available;
    margin-left: -80%;
  }
`
